
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList,IonSelect, IonSelectOption, IonIcon, IonItem, IonLabel } from '@ionic/vue';


import HeaderOne from '@/components/HeaderOne.vue'
import Accordion from "@/components/accordion.vue";
import AccordionItem from "@/components/accordion-item.vue";
export default {
  name: 'Stundenplan',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    HeaderOne,
    IonList,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonItem,
    IonLabel,
    Accordion,
    AccordionItem
  },


}

