<template>
  <ion-page>
    <HeaderOne v-bind:name="$t('timetable')" />
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">
            {{$t('timetable')}}
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ul class="treeview">
        <li><a >FB Wirtschaft</a></li>
        <ul>
          <li><a >Betriebswirtschaftslehre (Bachelor)</a>
            <ul>
              <li><a>1. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlb-1.sem_1.gr.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlb-1.sem_2.gr.html" target="Inhalt">2. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlb-1.sem_3.gr.html" target="Inhalt">3. Gruppe</a></li>
                </ul>
              </li>
              <li><a>3. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlb-3.sem_1.gr.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlb-3.sem_2.gr.html" target="Inhalt">2. Gruppe</a></li>
                </ul>
              </li>
              <li><a>5. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlb-5.sem_1.gr.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlb-5.sem_2.gr.html" target="Inhalt">2. Gruppe</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><a >Betriebswirtschaftslehre (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/bwlm-1.sem_1.gr.html" target="Inhalt">1. Semester</a></li>

            </ul>
          </li>
          <li><a >Wirtschaftsinformatik (Bachelor)</a>
            <ul>
              <li><a>1. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wib-1.sem_1.gr.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wib-1.sem_2.gr.html" target="Inhalt">2. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wib-1.sem_3.gr.html" target="Inhalt">3. Gruppe</a></li>
                </ul>
              </li>
              <li><a>3. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wib-3.sem_1.gr.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wib-3.sem_2.gr.html" target="Inhalt">2. Gruppe</a></li>
                </ul>
              </li>
              <li><a>5. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wib-5.sem_1.gr.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wib-5.sem_2.gr.html" target="Inhalt">2. Gruppe</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><a >Wirtschaftsinformatik (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wim-1.sem_1.gr.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wim-3.sem_1.gr.html" target="Inhalt">3. Semester</a></li>
            </ul>
          </li>
          <li><a >Security Management (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/secman.html" target="Inhalt">1. Semester</a></li>
            </ul>
          </li>
          <li><a >Technologie- und Innovationsmanagement (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/tim-1.sem.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/tim-2.sem.html" target="Inhalt">2. Semester</a></li>
            </ul>
          </li>
        </ul>
        <p> </p>
        <li><a >FB Informatik und Medien</a></li>
        <ul>
          <li><a >Applied Computer Science (Bachelor)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/acs-1-1.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/acs-3.html" target="Inhalt">3. Semester</a></li>
            </ul>
          </li>
          <li><a >Digitale Medien (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/dmm-1.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/dmm-2.html" target="Inhalt">2. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/dmm-3.html" target="Inhalt">3. Semester</a></li>
            </ul>
          </li>
          <li><a >Informatik (Bachelor)</a>
            <ul>
              <li><a>1. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infb-1-1.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infb-1-2.html" target="Inhalt">2. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infb-1-3.html" target="Inhalt">3. Gruppe</a></li>
                </ul>
              </li>
              <li><a>3. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infb-3-1.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infb-3-2.html" target="Inhalt">2. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infb-3-3.html" target="Inhalt">3. Gruppe</a></li>
                </ul>
              </li>
              <li><a>5. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infb-5-1.html" target="Inhalt">1. Gruppe</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><a >Informatik (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infm-1.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infm-2.html" target="Inhalt">2. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/infm-3.html" target="Inhalt">3. Semester</a></li>
            </ul>
          </li>
          <li><a >Medizininformatik (Bachelor)</a>
            <ul>

              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/medi-1-1.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/medi-3-1.html" target="Inhalt">3. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/medi-5.html" target="Inhalt">5. Semester</a></li>
            </ul>
          </li>
        </ul>
        <p> </p>
        <li><a >FB Technik</a></li>
        <ul>
          <li><a >Augenoptik/Optische Gerätetechnik (Bachelor)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/aog-1.html" target="Inhalt">1. Semester</a></li>
              <li><a>3. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/aog-3-1-ao.html" target="Inhalt">1. Gruppe AO</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/aog-3-2-og.html" target="Inhalt">2. Gruppe OG</a></li>
                </ul>
              </li>
              <li><a>5. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/aog-5-1-ao.html" target="Inhalt">1. Gruppe AO</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/aog-5-2-og.html" target="Inhalt">2. Gruppe OG</a></li>
                </ul>
              </li>
              <li><a>7. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/aog-7-1-ao.html" target="Inhalt">1. Gruppe AO</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/aog-7-2-og.html" target="Inhalt">2. Gruppe OG</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><a >Elektromobilität (Bachelor)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/emo-1.html" target="Inhalt">1. Semester</a></li>

            </ul>
          </li>
          <li><a >Energieeffizienz Technischer Systeme (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/enef-1.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/enef-2.html" target="Inhalt">2. Semester</a></li>
            </ul>
          </li>

          <li><a >Ingenieurwissenschaften (Bachelor)</a>
            <ul>
              <li><a>1. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-1-1.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-1-2.html" target="Inhalt">2. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-1-3.html" target="Inhalt">3. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-1-4.html" target="Inhalt">4. Gruppe</a></li>
                </ul>
              </li>
              <li><a>3. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-3-1-at.html" target="Inhalt" >1. Gruppe AT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-3-2-eit.html" target="Inhalt">2. Gruppe EIT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-3-3-mt.html" target="Inhalt" >3. Gruppe MT</a></li>

                </ul>
              </li>
              <li><a>5. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-5-1-at.html" target="Inhalt" >1. Gruppe AT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-5-2-eit.html" target="Inhalt">2. Gruppe EIT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-5-3-mt.html" target="Inhalt" >3. Gruppe MT</a></li>
                </ul>
              </li>
              <li><a>7. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-7-1-at.html" target="Inhalt" >1. Gruppe AT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-7-2-eit.html" target="Inhalt">2. Gruppe EIT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/iw-7-3-mt.html" target="Inhalt" >3. Gruppe MT</a></li>

                </ul>
              </li>
            </ul>
          </li>
          <li><a >Maschinenbau (Bachelor)</a>
            <div class="comment">
              <p style="color:black; font-weight: normal;">
                ANT - Antriebstechnik<br>
                EVT - Energie-  und Verfahrenstechnik<br>
                PE - Produktentwicklung
              </p>
            </div>
            <ul>
              <li><a>1. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-1-1.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-1-2.html" target="Inhalt">2. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-1-3.html" target="Inhalt">3. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-1-4.html" target="Inhalt">4. Gruppe</a></li>
                </ul>
              </li>
              <li><a>3. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-3-ant.html" target="Inhalt">1. Gruppe ANT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-3-evt.html" target="Inhalt">2. Gruppe EVT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-3-pe.html"  target="Inhalt">3. Gruppe PE</a></li>
                </ul>
              </li>
              <li><a>5. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-5-ant.html" target="Inhalt">1. Gruppe ANT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-5-evt.html" target="Inhalt">2. Gruppe EVT</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-5-pe.html"  target="Inhalt">3. Gruppe PE</a></li>
                </ul>
              </li>
              <li><a>7. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-7-1-am.html" target="Inhalt">1. Gruppe AM</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-7-2-am.html" target="Inhalt">2. Gruppe AM</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mb-7-eut.html"  target="Inhalt">3. Gruppe EUT</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><a >Maschinenbau (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mbm-1.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/mbm-2.html" target="Inhalt">2. Semester</a></li>
            </ul>
          </li>

          <li><a >Photonics (Master)</a>
            <ul>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/pht-1.html" target="Inhalt">1. Semester</a></li>
              <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/pht-3.html" target="Inhalt">3. Semester</a></li>
            </ul>
          </li>
          <li><a >Wirtschaftsingenieurwesen (Bachelor)</a>
            <ul>
              <li><a>1. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-1-1.html" target="Inhalt">1. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-1-2.html" target="Inhalt">2. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-1-3.html" target="Inhalt">3. Gruppe</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-1-4.html" target="Inhalt">4. Gruppe</a></li>
                </ul>
              </li>

              <li><a>3. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-3-1-eit.html" target="Inhalt">1. Gruppe (EIT)</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-3-1-eut.html" target="Inhalt">2. Gruppe (EUT)</a></li>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-3-1-mt.html" target="Inhalt">3. Gruppe (MT)</a></li>
                </ul>
              </li>
              <li><a>5. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-5-1-eit.html" target="Inhalt">1. Gruppe (EIT)</a></li>
                </ul>
              </li>
              <li><a>7. Semester</a>
                <ul>
                  <li><a href="https://informatik.th-brandenburg.de/Stundenplan/ws2021/plan/wing-7-1-eit.html" target="Inhalt">1. Gruppe (EIT)</a></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>

      </ul>

    </ion-content>
  </ion-page>

</template>

<script lang="ts">
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList,IonSelect, IonSelectOption, IonIcon, IonItem, IonLabel } from '@ionic/vue';


import HeaderOne from '@/components/HeaderOne.vue'
import Accordion from "@/components/accordion.vue";
import AccordionItem from "@/components/accordion-item.vue";
export default {
  name: 'Stundenplan',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    HeaderOne,
    IonList,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonItem,
    IonLabel,
    Accordion,
    AccordionItem
  },


}

</script>
<style>
#Stundenplan {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}
</style>